.sendLinkWrapper {
    .cardHeader {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cecece;
        padding-bottom: 1rem;
    }

    .mainBody {
        padding: 2rem 0;

        .ant-card {
            .ant-card-head {
                background: #001f3f;
                color: white;

                .ant-card-extra {
                    button {
                        color: white;
                    }
                }
            }
        }
        .submitWrapper {
            border-top: 1px solid #cecece;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem 0;
        }
    }
}