@font-face {
    font-family: 'Open Sans';
    src: url('../Assets/fonts/static/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/fonts/static/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  