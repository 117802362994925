.footerMain {
    background: #000;

    .upperSection {
        padding: 3rem 10vw 3rem 8vw;

        .headingSection {
            display: flex;
            height: 100%;
            justify-content: flex-start;
            flex-direction: column;
            padding-right: 5rem;

            @media screen and (max-width:768px) {
                padding-right: 0rem;
            }

            h2 {
                color: white;
                font-size: 22px;
                font-family: 'Ope Sans', sans-serif !important;
                margin-bottom: 0.5rem;
            }

            span {
                color: #bcbcbc;
                font-size: 15px;
                font-family: 'Open Sans', sans-serif !important;
            }

            p {
                color: #bcbcbc;
                font-size: 14px;
                font-family: 'Open Sans', sans-serif !important;
            }

        }

        .middleSection {
            display: flex;
            justify-content: center;
            width: 100%;

            @media screen and (max-width:768px) {
                justify-content: flex-start;
            }

            .textSection {
                h2 {
                    color: white;
                    font-size: 22px;
                    font-family: 'Open Sans', sans-serif !important;
                    margin-bottom: 0.5rem;
                }

                p {
                    color: #bcbcbc;
                    font-size: 15px;
                    font-family: 'Open Sans', sans-serif !important;
                }

                .iconsList {
                    display: flex;
                    flex-direction: column;

                    a {
                        color: #bcbcbc;
                        font-family: 'Open Sans', sans-serif !important;
                        padding: 0.2rem 0;
                        text-decoration: none;

                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }


        .rightSection {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            @media screen and (max-width:768px) {
                justify-content: flex-start;
            }

            .textSection {
                h2 {
                    color: white;
                    font-family: 'Open Sans', sans-serif !important;
                    margin-bottom: 0.5rem;
                    font-size: 22px;
                }

                .iconsList {
                    display: flex;
                    flex-direction: column;

                    a {
                        color: #bcbcbc;
                        font-family: 'Open Sans', sans-serif !important;
                        padding: 0.2rem 0;
                        text-decoration: none;

                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .lowerSection {
        .ant-row {
            .ant-col {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem 0 3rem 0;

                @media screen and (max-width:768px) {
                    padding: 0 0 1.5rem 0;
                    width: 100%;
                }

                &:first-child {
                    padding: 1rem 0rem 3rem 0rem;

                    @media screen and (max-width:768px) {
                        padding: 1rem 0 1.5rem 0;
                        width: 100%;
                    }
                }

                &:last-child {
                    padding: 1rem 3rem 3rem 0;

                    @media screen and (max-width:768px) {
                        padding: 0 0 1.5rem 0;
                        width: 100%;
                    }
                }

                span {
                    color: #bcbcbc;
                    font-size: 15px;
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
    }
}

hr {
    color: #cecece !important;
}

a {
    text-decoration: none;
}