.pricingWrapper {
    height: 100%;
    padding: 1rem 2.5vw 3rem 2.5vw;

    .mainHeader {
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
            font-weight: 600;
            //font-family: 'Open Sans', sans-serif !important;
            font-family: 'Playfair Display', serif;
            line-height: 1.3em;
            font-size: 42px;
            color: #1e293b;
            margin-top: 1rem;

            @media screen and (max-width:768px) {
                font-size: 36px;
            }
        }
    }

    .cardContainerCOl {
        .ant-card {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            height: 100%;
            position: relative;

            @media screen and (max-width:768px) {
                margin: 1.5rem;
            }

            &.premiumCard {
                // height: 106%; // Slightly taller for Premium card
                background-color: #f7f7f7;
                border: 1px solid #e0e0e0;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px; // More prominent shadow for premium
                // margin-top: -15px;
            }

            .ant-card-head {
                .ant-card-head-wrapper {
                    .cardHeaderWrapper {
                        font-family: 'Open Sans', sans-serif !important;

                        .label {
                            color: #0f4bff;
                            padding: 1rem 0 0rem 0;
                            font-size: 1.2rem;
                            font-weight: 700;
                            font-family: 'Open Sans', sans-serif !important;
                            margin-bottom: 0.5rem;
                        }

                        .price {
                            font-size: 1.2rem;
                            padding: 0rem 0 1rem 0;
                        }

                        .desc {
                            p {
                                margin: 0;
                                color: #67768e;
                                font-size: 12px;
                                text-align: left;
                                padding: 0 0 1rem 0;
                                font-family: 'Open Sans', sans-serif !important;
                            }
                        }
                    }
                }
            }

            .recommendedTag {
                position: absolute;
                top: -15px; // Slightly higher position
                right: 10px;
                font-size: 12px;
                font-weight: 500;
                z-index: 1;
                background-color: #ffce00;
                color: black;
                padding: 0.3rem 1rem;
                border-radius: 4px;
            }

            .ant-card-body {
                padding: 10px 15px 15px 15px;

                .wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0.2rem 0;

                    @media screen and (max-width:768px) {
                        padding: 0.4rem 0;
                    }

                    .ant-col {
                        padding: 0 0.5rem;
                    }

                    p {
                        margin: 0;
                        text-transform: capitalize;
                        font-family: 'Open Sans', sans-serif !important;
                    }
                }

                .phoneConsultationRow {
                    .ant-col {
                        &:last-child {
                            @media screen and (max-width:768px) {
                                margin-left: 0.6rem;
                            }
                        }
                    }
                }
            }

            .ant-card-actions {
                background: transparent;

                .buyNowBtn {
                    font-family: 'Open Sans', sans-serif !important;
                    color: #0f4bff;
                    border: 1px solid #0f4bff;

                    &:hover {
                        background: #0f4bff;
                        color: white;
                        outline: none;
                    }
                }
            }
        }
    }
}