.ant-layout {
    height: 100%;
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif !important;

    .widgetWrapper {
        margin-bottom: 1.5rem;

        .ant-card {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;

            .ant-card-body {
                .ant-statistic-title {
                    font-weight: 500;
                    color: black;
                }

            }
        }
    }

    .chartRow {
        .ant-card {
            .ant-card-body {
                .chartHeader {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;

                    .ant-radio-button-wrapper {
                        @media screen and (max-width:650px) {
                            font-size: 10px;
                        }

                        background: #fff;
                        color: #001f3f;
                        outline: none;
                        box-shadow: none;
                    }

                    .ant-radio-button-wrapper-checked {

                        background: #001f3f;
                        color: white;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .tableRow {
        width: 100%;

        .ant-card {
            width: 100%;
            margin: 2rem 0;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .ant-table-wrapper {
                width: 100%;
                border-radius: 8px;
            }

            .viewMoreBtn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 1rem 0;
            }
        }
    }
}

.ant-table-container {
    .ant-table-thead {
        .ant-table-cell {
            background: #001f3f !important;
            color: white;
            font-size: 1.2rem;

            .ant-table-column-sorter {
                color: white !important;
            }

            .ant-dropdown-trigger {
                color: white !important;
            }

            &:hover {
                background: #001f3f !important;
            }
        }
    }

    .ant-table-tbody {
        .ant-table-cell {
            background: #f0f0f0;
            color: #000;
            font-size: 1rem;
            font-weight: 500;
        }
    }

}