.adminWrapper {
    background: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);
    height: 100%;
    min-height: 100vh;

    .loginSection {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-height: 100vh;


        .ant-card {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            width: 100%;
            min-width: 25vw;
            max-width: 25vw;

            @media screen and (max-width:1600px) {
                min-width: 30vw;
                max-width: 30vw;
            }

            @media screen and (max-width:600px) {
                width: 100%;
                min-width: 85vw;
                max-width: 85vw;
            }

            .loginHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;

                .textWrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    // border-bottom: 1px solid #cecece;
                    padding-bottom: 1rem;

                    h2 {
                        margin: 0;
                    }
                }
            }

            .formWrapper {
                padding-top: 1rem;

                .buttonWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                label {
                    opacity: 1;
                    font-weight: 500;
                    color: #000;
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
    }
}