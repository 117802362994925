.packageAdminContent {
    .packageMain {
        .pageHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1rem;

            .addNewPackage {
                button {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    transition: 0.2s all ease-in-out;
                    background-image: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);
                    color: white;
                    border: none;
                    outline: none;

                    &:hover {
                        border: none;
                        outline: none;
                        margin-top: -5px;
                    }
                }
            }
        }

        .ant-form {
            .submitWrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}