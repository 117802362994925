.ant-layout-sider {
    @media screen and (max-width:650px) {
        height: 100%;
        position: absolute !important;
        z-index: 99999999;
    }

    .ant-layout-sider-children {
        // background: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);
        background: #001f3f;

        .headerLogoWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem 0;
            font-family: 'Open Sans', sans-serif !important;
            background: #001f3f;
            flex-direction: column;

            h3 {
                margin: 0;
                color: white;
            }

            span {
                font-size: 10px;
                color: white;
            }
        }

        .ant-menu {
            padding-top: 2rem;
            background: transparent;

            .ant-menu-item {
                font-family: 'Open Sans', sans-serif !important;
                margin: 0 0 1rem 0;
                color: white;
            }

            .ant-menu-item-selected {
                background: white;
                color: #054cff;
                font-weight: bold;
            }
        }
    }
}