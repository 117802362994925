.whychooseWrapper {
    background-color: #EBF4F6;
    padding: 2rem 0;

    .mainHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 1rem 0 1rem 0;

        h2 {
            font-size: 42px;
            font-weight: 600;
            font-family: 'Playfair Display', serif;
            //font-family: 'Open Sans', sans-serif !important;
            line-height: 1.3em;
            color: #1e293b;
            margin: 0 0 0.2rem 0;
        }
    }

    .ant-row {
        .ant-col {
            padding: 1rem 2rem;

            .ant-card {
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                transition: 0.2s all ease-in-out;
                height: 100%;

                &:hover {
                    // margin-top: -5px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                }

                .ant-card-body {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 2vw 5vw;

                    .ant-image {
                        height: 100%;
                        width: 100%;

                        img {
                            height: 100%;
                            width: 100%;
                            max-height: 20vh;
                            object-fit: contain;
                        }
                    }

                    h2 {
                        margin-bottom: 0;
                        color: #1e293b;
                        font-family: 'Playfair Display', serif;
                    }

                    p {
                        margin-top: 0;
                        color: #1e293b;
                        font-family: 'Open Sans', sans-serif !important;
                    }
                }
            }
        }
    }
}