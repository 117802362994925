.linkedWrapper {
    .topSection {
        padding: 1rem 8vw;

        .leftSection {
            .imgWrapper {
                padding: 2rem 0rem;
                padding-bottom: 0;

                @media screen and (max-width:768px) {
                    padding: 1rem 2rem;
                }

                .videoWrapper {
                    width: 100% !important;

                    @media screen and (max-width:768px) {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }

        }

        .RightSection {
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0;

            h2 {
                // color: #5a3f93;
                color: #1e293b;
                font-size: 2.5rem;
                font-family: 'Playfair Display', serif;
                //font-family: 'Open Sans', sans-serif !important;

                @media screen and (max-width:768px) {
                    margin: 0;
                }
            }

            p {
                font-family: 'Open Sans', sans-serif !important;
                color: #1e293b; 
            }
        }
    }

    .bottomSection {
        padding: 0 9vw 2rem 8vw;

        .listWrapper {
            width: 100%;
            background: #2d436c;
            border: 2px solid black;
            padding: 1rem 2rem;

            h2 {
                margin: 0;
                color: white;
                font-family: 'Open Sans', sans-serif !important;

            }

            ul {
                padding-left: 1rem;

                li {
                    color: white;
                    padding: 1rem 0;
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
    }
}