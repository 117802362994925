body {
    padding: 0;
    margin: 0;
}

.detailFormWrapper {
    padding: 0;
    margin: 0;
    height: 100%;
    min-height: 100vh;
    background: #f3f4f7;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width:1200px) and (max-width:1600px) {
        margin-top: 5rem;   
    }

    .ant-card {
        width: 100%;
        min-width: 30vw;
        max-width: 30vw;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        @media screen and (min-width:1200px) and (max-width:1600px) {
            min-width: 40vw;
            max-width: 40vw;
        }
        @media screen and (max-width:768px) {
            min-width: auto !important;
            max-width: 100%;
            margin: 2rem;
        }

        .formWrapper {
            .formItemWrapper {
                label {
                    font-weight: 500;
                }
            }

            .buttonWrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .cancelBtn {
                    background: white;
                    font-weight: 600;

                    &:hover {
                        outline: none;
                        // border: none;
                    }
                }

                .submitBtn {
                    border: none;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    background: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);

                }
            }
        }
    }

    .loaderWrapper {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 99999;
    }
}