.serviceWrapper {
    padding: 2px 0 5rem 0;
    width: 100%;
    background-color: #EBF4F6;

    @media screen and (max-width:768px) {
        padding: 2px 0 2rem 0;
    }

    h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1e293b;
        font-size: 42px;
        font-weight: 600;
        font-family: 'Playfair Display', serif;
        // font-family: 'Open Sans', sans-serif !important;
        line-height: 1.3em;
        margin-bottom: 3rem;

        @media screen and (max-width:768px) {
            font-size: 36px;
            margin: 1.5rem;
        }
    }

    .mainRow {
        .ant-col {
            padding: 0rem 0.8rem 0 1rem;

            @media screen and (max-width:768px) {
                margin-bottom: 1.5rem;
            }

            .ant-card {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                transition: 0.3s all ease-in-out;
                height: 100%;
                border: 2px solid #fff;


                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    border: 2px solid #054cff;
                }

                .ant-card-body {
                    .imageWrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                            max-height: 30vh;
                        }
                    }

                    .textSection {

                        h2 {
                            text-align: center;
                            color: #1e293b;
                            font-weight: 600;
                            font-family: 'Plus Jakarta Sans', sans-serif;
                            line-height: 1.3em;
                            font-family: 'Playfair Display', serif;
                            //font-family: 'Open Sans', sans-serif !important;
                        }

                        p {
                            color: #67768e;
                            font-size: 14.5px;
                            margin: 0;
                            text-align: left;
                            font-family: 'Open Sans', sans-serif !important;
                        }
                    }
                }
            }
        }
    }

}