.contactMainWrapper {
    padding: 0;

    .leftSection {
        padding: 2vw 2vw 2vw 8vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .header {
            h2 {
                color: #1e293b;
                font-size: 42px;
                font-weight: 600;
                //font-family: 'Plus Jakarta Sans', sans-serif;
                font-family: 'Playfair Display', serif;
                line-height: 1.3em;
                margin: 0;
            }

            p {
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                color: #67768e;
                margin: 1.5rem 0;
                font-family: 'Open Sans', sans-serif !important;
            }
        }


        .emailSection {
            padding: 1rem 0;

            p {
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                color: #67768e;
                margin: 0.5rem 0;
                font-family: 'Open Sans', sans-serif !important;
            }

            b {
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                color: #67768e;
                font-family: 'Open Sans', sans-serif !important;
            }
        }
    }

    .rightSection {
        padding: 1vw 10vw 1vw 0;

        @media screen and (max-width:768px) {
            padding: 0 2rem 1rem 2rem;
        }
    }
}