.checkoutMain {
    .headerSection {
        border-bottom: 1px solid #cecece;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.8rem 11rem;

        @media screen and (max-width:768px) {
            justify-content: space-between;
            padding: 0.8rem 1rem;
        }

        span {
            font-weight: 700;
            font-size: 1.8rem;
        }
    }

    .checkoutWrapper {
        // display: flex;
        // justify-content: space-between;

        .leftSection {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding-bottom: 5rem;

            .ant-form {
                min-width: 20.5vw;
                padding: 2rem 1rem 2rem 0;
                // max-width: 25.5vw;

                .ant-form-item-explain-error {
                    font-size: 8px;
                }

                .privacySection {
                    label {
                        font-size: 16px;
                        cursor: pointer;
                        opacity: 0.9;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .formItemWrapper {
                    .ant-form-item {
                        margin-bottom: 10px;

                        input {
                            font-size: 16px;
                            padding: 0.5rem;
                            border-radius: 2px;
                        }

                        .ant-select-selector {
                            border-radius: 2px;

                            .ant-select-selection-placeholder {
                                font-size: 16px;
                            }
                        }
                    }

                    label {
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }
                }

                .splitRow,
                .threeSPlitRow {
                    display: flex;
                    justify-content: space-between;
                }

                .formHeading {
                    display: flex;
                    flex-direction: column;

                    label {
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }

                    span {
                        font-size: 16px;
                    }
                }

                .paymentWrapper {
                    width: 100%;
                    padding: 1.5rem 0;
                    border-bottom: 1px solid #cecece;

                    button {
                        width: 100%;
                        // background: #F5F5F5;
                        background: #000;
                        color: white;
                        border-radius: 2px;
                        box-shadow: none;
                        border: 1px solid #cecece;
                        padding: 1.5rem;
                    }
                }
            }
        }

        .rightSection {
            background-color: #f5f5f5;
            padding: 20px;
            width: 50%;
            padding-bottom: 5rem;
            // height: 100vh;

            .packageSection {
                width: 100%;
                max-width: 24vw;
                padding-top: 1rem;

                .packageHeader {
                    text-align: center;
                    margin-bottom: 20px;

                    h3 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #333;
                    }
                }

                .packageDetails {

                    .title,
                    .price,
                    .subtotal {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        h5,
                        h6 {
                            font-size: 1.3rem;
                            font-weight: 500;
                        }
                    }

                    .value {
                        font-weight: 500;
                        color: #333;
                    }

                    .total {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        h5 {
                            font-size: 1.3rem;
                            font-weight: bolder;
                            color: #000;
                        }

                        span {
                            font-size: 8px;
                            padding-right: 0.2rem;
                            font-weight: 400;
                        }
                    }
                }

                .amountWrapper {
                    margin-top: 5px;
                    padding-top: 10px;
                    border-top: 1px solid #ddd;
                }
            }
        }
    }

    .accordion {
        display: none;
    }

    @media (max-width: 768px) {
        .headerSection span {
            font-size: 18px !important;
        }

        .accordion {
            background-color: #f9f9f9;
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            padding: 1rem;
            margin-bottom: 1rem;
            display: block;
        }

        .accordion-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            font-size: 1rem;

            .iconWrapper {
                display: flex;
                align-items: center;

                h5 {
                    font-size: 12px;
                    margin-bottom: 0;
                    color: blue;
                }

                svg {
                    margin-left: 0.4rem;
                }
            }

            h4 {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }

        .accordion-body {
            padding: 0.5rem 0;

            .packageSection {
                width: 100%;
                padding-top: 1rem;

                .packageHeader {
                    text-align: center;
                    margin-bottom: 20px;

                    h3 {
                        font-size: 1rem;
                        font-weight: 600;
                        color: #333;
                    }
                }

                .packageDetails {

                    .title,
                    .price,
                    .subtotal {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        h5,
                        h6 {
                            font-size: 0.8rem;
                            font-weight: 500;
                        }
                    }

                    .value {
                        font-weight: 500;
                        color: #333;
                    }

                    .total {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        h5 {
                            font-size: 0.8rem;
                            font-weight: bolder;
                            color: #000;
                        }

                        span {
                            font-size: 8px;
                            padding-right: 0.2rem;
                            font-weight: 400;
                        }
                    }
                }

                .amountWrapper {
                    margin-top: 5px;
                    padding-top: 10px;
                    border-top: 1px solid #ddd;
                }
            }
        }

        // .accordion .icon {
        //     font-size: 1.5rem;
        // }

        .accordion.open .accordion-body {
            display: block;
            transition: all 0.3s ease-in-out;
        }

        .accordion:not(.open) .accordion-body {
            display: none;
        }



        .checkoutWrapper {
            flex-direction: column;


            .leftSection,
            .rightSection {
                width: 100%;
            }

            .leftSection {
                .ant-form {
                    min-width: 100%;
                    max-width: 100%;
                    padding: 1rem;

                    .formItemWrapper {

                        label,
                        input {
                            font-size: 15px !important;
                        }

                        .ant-select-selection-placeholder {
                            font-size: 15px !important;
                        }
                    }

                    .formHeading {
                        label {
                            font-size: 15px;
                        }
                    }
                }
            }

            .rightSection {
                padding: 10px;
                display: none;
            }
        }

    }

}

.privacyModel {
    .ant-modal-body {
        .ant-collapse {
            margin: 2rem 0;

            .ant-collapse-header-text {
                font-weight: 500;
            }
        }
    }
}