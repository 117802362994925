.bannerWrapper {
    //background: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);
    height: 100%;
    min-height: 70vh;
    max-height: 70vh;

    @media screen and (max-width:768px) {
        min-height: 80vh;
        max-height: 80vh;
    }

    .mainBannerRow {
        min-height: 55vh;
        max-height: 55vh;
        height: 100%;

        .leftSection {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            padding: 0 0 0 8rem;

            @media screen and (max-width:768px) {
                padding: 0 3rem;
                order: 2;
            }

            h1 {
                font-size: 5rem;
                font-weight: 600;
                // font-family: 'Open Sans', sans-serif !important;
                font-family: 'Playfair Display', serif;
                line-height: 1.4em;
                // color: white;
                margin-bottom: 20px;
                color: #1e293b;

                @media screen and (max-width:768px) {
                    font-size: 2.5rem;
                }

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 15.75px;
                // color: white;
                // font-family: 'Open Sans', sans-serif !important;
                font-family: 'Playfair Display', serif;
                margin-bottom: 1.6em;
                color: #1e293b;
            }

            button {
                border-style: solid;
                border-width: 0px;
                border-color: #ffcd57;
                background-color: #ffcd57;
                color: #1e293b;
                font-family: inherit;
                font-weight: 600;
                line-height: 0.5em;
                font-size: 16px;
                font-size: 1.1111111111111rem;
                border-radius: 50px;
                padding: 20px 25px;
                width: fit-content;
                font-weight: 700;

                &:hover {
                    border-color: #ffcd57;
                    background-color: #ffcd57;
                    color: #1e293b;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                }
            }
        }

        .rightSection {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            text-align: left;
            padding: 0;

            @media screen and (max-width:768px) {
                padding: 3rem 1rem 0 1rem;
                order: 1;
                width: 100%;
            }

            .imgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 3rem;
                height: 100%;

                @media screen and (max-width:768px) {
                    padding: 0;
                }

                .ant-image {
                    width: 100%;

                    img {
                        max-height: 60vh;
                        object-fit: contain;

                        @media screen and (max-width:768px) {
                            max-height: 35vh;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}