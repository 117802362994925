.ant-layout {
    .ant-layout-header {
        // background: linear-gradient(325deg, #020069 0%, #054cff 73%, #0f4bff 100%) !important;
        background-color: transparent !important;
        .headderWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        
            .signOutWrapper {
                padding: 0 2rem;
                font-family: 'Open Sans', sans-serif !important;
                font-weight: 700;
                // color: white;
                cursor: pointer;

                svg {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}