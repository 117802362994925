.aboutWrapper {
    background: whitesmoke;

    .leftSection {
        padding: 2rem 0 2rem 8vw;

        @media screen and (max-width:768px) {
            padding: 1rem 2rem;
            order: 2;
        }

        h1 {
            color: #1e293b;
            font-size: 42px;
            font-weight: 600;
            font-family: 'Playfair Display', serif;
            // font-family: 'Plus Jakarta Sans', sans-serif;
            line-height: 1.3em;
            margin: 0.2rem 0 0.6rem 0;
        }

        p {
            color: #67768e;
            font-size: 1.1rem;
            margin: 0;
            font-family: 'Open Sans', sans-serif !important;
        }
    }

    .rightSection {
        padding: 2rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .imgWrapper {
            @media screen and (max-width:768px) {
                padding: 3rem;
                padding-bottom: 0;
            }

            .ant-image {
                img {
                    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    max-height: 60vh;
                }
            }
        }
    }
}