.faqWrapper {
    margin-top: 5rem;
    padding: 1rem 10vw;
    font-family: 'Open Sans', sans-serif !important;

    .headingSection {
        h1 {
            font-family: 'Playfair Display', serif;
        }
    }

    .ant-collapse-header-text {
        font-weight: bold;
    }
}