@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    margin: 0;
        font-family: 'Open Sans', sans-serif !important;
}

html {
    scroll-behavior: smooth;
  }

  .loaderWrapperComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .spinner {
        width: 65px;
        aspect-ratio: 1;
        position: relative;
      }
      .spinner:before,
      .spinner:after {
        content: "";
        position: absolute;
        border-radius: 50px;
        box-shadow: 0 0 0 3px inset #000;
        animation: l4 2.5s infinite;
      }
      .spinner:after {
        animation-delay: -1.25s;
      }
      @keyframes l4 {
        0% {
          inset: 0 35px 35px 0;
        }
        12.5% {
          inset: 0 35px 0 0;
        }
        25% {
          inset: 35px 35px 0 0;
        }
        37.5% {
          inset: 35px 0 0 0;
        }
        50% {
          inset: 35px 0 0 35px;
        }
        62.5% {
          inset: 0 0 0 35px;
        }
        75% {
          inset: 0 0 35px 35px;
        }
        87.5% {
          inset: 0 0 35px 0;
        }
        100% {
          inset: 0 35px 35px 0;
        }
      }
  }
