    .headerWrapper {
        padding: 0.5rem 8vw;

        .imageColumn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h3 {
                // margin: 0;
                // // color: white;
                // font-family: 'Open Sans', sans-serif !important;
                // font-size: 1.5em;
                // color: #1e293b;
                margin: 0;
                font-family: fantasy;
                font-size: 1.8em;
                color: #1e293b;
                font-weight: 200;
            }

            span {
                font-size: 10px;
                color: #1e293b;
                // color: white;
                font-family: 'Open Sans', sans-serif !important;
            }
        }

        .listColumn {
            ul {
                display: flex;
                justify-content: center;
                gap: 4vw;
                align-items: center;
                margin-bottom: 0;

                li {
                    list-style-type: none;
                    padding-bottom: 0.8rem;

                    a {
                        // color: #000;
                        color: #1e293b;
                        //color: rgba(242, 245, 247, 0.76);
                        font-weight: bold;
                        font-family: 'Open Sans', sans-serif !important;
                        text-decoration: none !important;

                        &:hover {
                            color: #000;
                        }
                    }
                }

                .active {
                    border-bottom: 1px solid #000;
                    //color: #fff;
                    font-family: 'Open Sans', sans-serif !important;
                }

            }
        }

        .linkColumn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: -10px;

            a {
                padding: 0 1vw;
                // color: #000;
                color: #1e293b;

                svg {
                    font-size: 1.4rem;
                }

            }
        }
    }

    .headerWrapper {
        transition: top 0.3s;
        z-index: 1000;
    }

    .headerWrapper.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: white;
        //background: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);

        ul {
            li {
                a {
                    color: #1e293b;
                    //color: rgba(242, 245, 247, 0.76);
                    font-family: 'Open Sans', sans-serif !important;

                    &:hover {
                        color: #000;
                    }

                }

            }

            .active {
                border-bottom: 1px solid #000;

                a {
                    color: #000;
                    // color: #fff;
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
    }

    .ant-drawer-body {
        background: #1e293b;
        //background: linear-gradient(35deg, #020069 0%, #054cff 73%, #0f4bff 100%);

        ul {
            padding: 0;
            width: 100%;

            li {
                padding: 1rem;
                list-style: none;
                color: white;
                font-family: 'Open Sans', sans-serif !important;

                a {
                    color: white;
                    font-family: 'Open Sans', sans-serif !important;
                    font-size: 1.5rem;
                }
            }
        }
    }