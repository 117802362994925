.reverseWrapper {
    .topSection {
        padding: 1rem 9vw 1rem 6.5vw;
        background: whitesmoke;

        .leftSection {
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0;

            @media screen and (max-width:768px) {
                padding: 1rem;
            }

            h2 {
                // color: #5a3f93;
                color: #1e293b;
                font-size: 2.5rem !important;
                font-family: 'Playfair Display', serif;
                //font-family: 'Open Sans', sans-serif !important;

                @media screen and (max-width:768px) {
                    margin: 0;
                }
            }

            p {
                font-family: 'Open Sans', sans-serif !important;
                font-size: 1rem;
                color: #1e293b;
            }
        }

        .RightSection {
            .imgWrapper {
                padding: 2rem 0rem;
                padding-bottom: 0;

                @media screen and (max-width:768px) {
                    padding: 1rem 2rem;
                }

                .videoWrapper {
                    width: 100% !important;

                    @media screen and (max-width:768px) {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }

    .bottomSection {
        padding: 0 9vw 2rem 8vw;
        background: whitesmoke;

        .listWrapper {
            width: 100%;
            background: #2d436c;
            border: 2px solid black;
            padding: 1rem 1.5rem;
            border-radius: 2px;

            h2 {
                margin: 0;
                color: white;
                font-family: 'Open Sans', sans-serif !important;
            }

            ul {
                padding-left: 1rem;

                li {
                    color: white;
                    padding: 1rem 0;
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
    }
}