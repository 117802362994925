.productWrapper {
    background-color:#EBF4F6;
    min-height: 300px;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0px;

    .ant-row {
        height: 100%;
        min-height: 300px;

        .ant-col {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .iconSection {
                display: flex;
                justify-content: center;
                padding: 0 3rem;
                align-items: center;
            }

            .textSection {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: left;
                padding: 0 3rem;
                overflow-wrap: break-word;
                word-break: break-word;

                h3 {
                    color: #1e293b;
                    font-size: 1.3333333333333rem;
                    font-weight: 600;
                    font-family: 'Open Sans', sans-serif !important;
                    line-height: 1.3em;
                    margin: 16px 0;
                }

                p {
                    color: #67768e;
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
    }
}